import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { saveAs } from "file-saver";

const EpaperDetails = () => {
  const { epaperId } = useParams();
  const [newsDetails, setNewsDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNewsDetails = async () => {
      try {
        const { data } = await axios.get(
          `https://localhost:44351/api/home/getEpaperDetails/${epaperId}`
        );
        setNewsDetails(data);
        setLoading(false);
      } catch (error) {
        setError("समाचार विवरण लाने में त्रुटि");
        setLoading(false);
      }
    };

    fetchNewsDetails();
  }, [epaperId]);

  const baseUrl = "https://localhost:44351/";

  const handleDownload = async () => {
    const filePath = newsDetails.pdfUrlNews; // pdfUrlNews से फ़ाइल पथ प्राप्त करें
  
    if (!filePath) {
      alert("फाइल का पथ उपलब्ध नहीं है।");
      return;
    }
  
    try {
      console.log(`Fetching file from: ${baseUrl}${filePath}`); // Debugging log
  
      // फाइल को Blob के रूप में लाना
      const response = await axios.get(`${baseUrl}${filePath}`, {
        responseType: "blob", // रिस्पांस टाइप को blob सेट करें
      });
  
      console.log(response); // Check the entire response object
  
      // Check if response is valid
      if (response.status === 200) {
        const fileName = filePath.split("/").pop(); // फाइल का नाम सेट करें
        console.log(`File ${fileName} downloaded successfully.`); // Debugging log
        saveAs(response.data, fileName); // Blob को डाउनलोड करने के लिए saveAs का उपयोग करें
      } else {
        console.error(`Error: Response status ${response.status}`); // Debugging log
        alert("फाइल डाउनलोड करने में त्रुटि। कृपया पुनः प्रयास करें।");
      }
    } catch (error) {
      console.error("फाइल डाउनलोड करने में त्रुटि:", error);
      alert("फाइल डाउनलोड करने में त्रुटि। कृपया पुनः प्रयास करें।");
    }
  };
  

  if (loading) return <div>लोड हो रहा है...</div>;
  if (error) return <div>{error}</div>;
  if (!newsDetails) return <div>इस ई-पेपर के लिए कोई विवरण उपलब्ध नहीं है।</div>;

  return (
    <div style={{ backgroundColor: "black" }}>
      <HelmetProvider>
        <Helmet>
          <title>शब्द टुडे  | ई-पेपर</title>
        </Helmet>
      </HelmetProvider>

      <div className="ak-content">
        <Link to="/Epaper">
          <div style={{ color: "white", marginTop: "20px" }}>
            <img
              className="site-logo"
              width={120}
              height={50}
              loading="lazy"
              src="/design/images/logo-mike1.png"
              alt="शब्द टुडे  Logo"
              style={{ paddingLeft: "10px" }}
            />
            <hr />
          </div>
        </Link>

        <div className="container" style={{ backgroundColor: "black" }}>
          <div className="row">
            <div className="ak_column_2 col-md-12 content-column">
              {/* यहाँ हम फ़ाइल दिखाते हैं */}
              {newsDetails.pdfUrlNews && (
                <div style={{ position: "relative", display: "flex", justifyContent: "center" }}>
                  <img
                    alt="E-paper"
                    src={`${baseUrl}${newsDetails.pdfUrlNews}`}
                    height="500px"
                    className="m-auto"
                    style={{ transform: "scale(1)" }}
                  />
                  {/* डाउनलोड बटन */}
                  <button
                    onClick={handleDownload}
                    style={{
                      position: "absolute",
                      right: "20px",  // दाईं ओर 20px
                      top: "-80px",    // ऊपर से 20px
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      zIndex: 10,     // आइकन को इमेज के ऊपर रखने के लिए
                    }}
                    className="download-icon"
                  >
                    <i class="fa fa-download" style={{color: "white" }} aria-hidden="true"></i>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div style={{ color: "white", marginTop: "0%" }}>
          <hr />
          <p style={{ textAlign: "center" }}>
            हमारे साथ विज्ञापन करें | www.shabadtoday.com | info@shabadtoday.org
          </p>
          <p style={{ textAlign: "center" }}>
            कॉपीराइट © 2024 शब्द टुडे .
          </p>
        </div>
      </div>
    </div>
  );
};

export default EpaperDetails;
