import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import BigAdd from './BigAdd'
import ButtonTags from './ButtonTags'
import SocialMediaShare from './SocialMediaShare'
import MadhyapradeshDetails1 from './MadhyapradeshDetails1'
import MadhyapradeshDetails2 from './MadhyapradeshDetails2'
import MadhyapradeshDetails3 from './MadhyapradeshDetails3'
import Madhyapradesh1 from './Madhyapradesh1'
import { Circles } from "react-loader-spinner";
import DOMPurify from 'dompurify'; // For sanitizing HTML content


const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength);
  }
  return text;
};

const stripHtmlTags = (html, maxLength) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  const strippedText = tempDiv.textContent || tempDiv.innerText || "";
  return truncateText(strippedText, maxLength);
};


function MadhyapradeshDetailsD() {
  const { mainKhabarId } = useParams();
  const [newsDetails, setNewsDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNewsDetails = async () => {
      try {
        const { data } = await axios.get(
          `https://api.shabdtoday.com/api/home/getAllDetails/${mainKhabarId}`
          // `https://localhost:44351/api/home/getAllDetails/${mainKhabarId}`
        );
        console.log("Fetched data:", data); // Log the fetched data
        setNewsDetails(data);
      } catch (error) {
        console.error("Error fetching news details:", error);
      }
    };

    fetchNewsDetails();
  }, [mainKhabarId]);


  const handleNewsClick2 = async (mainKhabarId) => {
    try {
      const response = await axios.post("https://api.shabdtoday.com/api/home/update-viewcount", {
        mainKhabarId: mainKhabarId
      });

      if (response.status === 200) {
        console.log("View count updated successfully", response.data);
        navigate(`/TajaKhabarDetailsNews/${mainKhabarId}`);
      } else {
        console.error("Failed to update view count", response.data);
      }
    } catch (error) {
      console.error("Error updating view count:", error.response ? error.response.data : error.message);
    }
  };


  const formatDateInHindi = (dateString) => {
    const date = new Date(dateString);
    const monthsInHindi = [
      "जनवरी",
      "फरवरी",
      "मार्च",
      "अप्रैल",
      "मई",
      "जून",
      "जुलाई",
      "अगस्त",
      "सितंबर",
      "अक्टूबर",
      "नवंबर",
      "दिसंबर",
    ];
    const seconds = date.getSeconds();
    const minute = date.getMinutes();
    const hour = date.getHours();
    const day = date.getDate();
    const month = monthsInHindi[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year} \n ${hour}:${minute}:${seconds}`;
  };

  const generateShareLinks = (mainKhabarId, newsHeading) => {
    const encodedNewsHeading = encodeURIComponent(newsHeading);
    const url = `${window.location.origin}/MadhyaPradeshDetailsD/${mainKhabarId}`;
    return {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${encodedNewsHeading}`,
      whatsapp: `https://api.whatsapp.com/send?text=${encodedNewsHeading} ${url}`,
      twitter: `https://twitter.com/intent/tweet?text=${encodedNewsHeading}&url=${url}`,
      instagram: `https://www.instagram.com/`


    };
  };

  if (!newsDetails) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Circles
          height="30"
          width="30"
          color="#f7265c"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  const BASE_URL = "https://api.shabdtoday.com/";
  const image_resize = "https://api.shabdtoday.com/api/home/resize";
  const renderHtmlContent = (html) => {
    const sanitizedHtml = DOMPurify.sanitize(html);
    return { __html: sanitizedHtml };
  };
  return (
    <div>
      <div className="ak-post-wrapper" style={{ transform: "none" }}>
        <div
          className="ak-content-wrap ak-post-wrap ak-layout-style-1 clearfix ak-post-style-7 clearfix"
          style={{ transform: "none" }}
        >
          <div className="ak-container" style={{ transform: "none" }}>

            <div className="ak-content" style={{ transform: "none" }}>
              <div className="containercustome" style={{ transform: "none" }}>
                <div className='mt-2'>
                  <BigAdd />
                </div>
                <div className="row grid-container" style={{ transform: "none" }}>
                  <div
                    className="grid-item ak_column_1 col-md-3 sidebar-column sidebar-column-primary sticky-sidebar "
                    style={{
                      position: "relative",
                      overflow: "visible",
                      boxSizing: "border-box",
                      minHeight: 1
                    }}
                  >
                    <div
                      className="sticky"
                      style={{
                        paddingTop: 0,
                        paddingBottom: 1,
                        position: "sticky",
                        transform: "none",
                        top: 0,
                        left: "914.5px"
                      }}
                    >
                      <div className="sidebar post-sidebar">

                        <div className="container">
                          <Madhyapradesh1 />

                        </div>

                        <MadhyapradeshDetails1 />
                        <SocialMediaShare />


                      </div>
                      <div
                        className="resize-sensor"
                        style={{
                          position: "absolute",
                          inset: 0,
                          overflow: "hidden",
                          zIndex: -1,
                          visibility: "hidden"
                        }}
                      >
                        <div
                          className="resize-sensor-expand"
                          style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            overflow: "hidden",
                            zIndex: -1,
                            visibility: "hidden"
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              left: 0,
                              top: 0,
                              transition: "all 0s ease 0s",
                              width: 410,
                              height: 1260
                            }}
                          />
                        </div>
                        <div
                          className="resize-sensor-shrink"
                          style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            overflow: "hidden",
                            zIndex: -1,
                            visibility: "hidden"
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              left: 0,
                              top: 0,
                              transition: "0s",
                              width: "200%",
                              height: "200%"
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid-item ak_column_2 col-md-6">
                    <header className="entry-header ak-post-header">
                      <div className="ak-post-badges" />
                      <span className="ak-post-summary mb-2" style={{ color: "crimson" }}>
                        {newsDetails.mainTazaKhabars &&
                          stripHtmlTags(
                            newsDetails.mainTazaKhabars.newsHeading,
                            60
                          )}:
                      </span>
                      <span className="ak-post-summary mb-2">
                        {newsDetails.mainTazaKhabars &&
                          stripHtmlTags(
                            newsDetails.mainTazaKhabars.newsDetails,
                            150
                          )}
                      </span>
                      <div style={{ display: "flex" }}>
                        <div style={{ fontSize: 15, marginRight: 460 }}>
                          {newsDetails.mainTazaKhabars &&
                            formatDateInHindi(
                              newsDetails.mainTazaKhabars.date
                            )}
                        </div>
                        <div>
                          <span>
                            <i className="fa fa-eye" style={{ fontSize: 12 }} aria-hidden="true">{newsDetails.mainTazaKhabars.viewCount}</i>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="text-dark mb-2" style={{ fontWeight: 600 }} >
                          {newsDetails.mainTazaKhabars &&
                            stripHtmlTags(
                              newsDetails.mainTazaKhabars.reporter,
                              150
                            )}
                        </div>,
                        <div className="text-dark mb-2" style={{ marginLeft: "10px", marginRight: 260, fontWeight: 600 }}>
                          {newsDetails.mainTazaKhabars &&
                            stripHtmlTags(
                              newsDetails.mainTazaKhabars.editor,
                              150
                            )}
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                          &nbsp;&nbsp;&nbsp;
                          <Link style={{ fontSize: 22, color: "grey" }} to={generateShareLinks(newsDetails.mainTazaKhabars.mainKhabarId, newsDetails.mainTazaKhabars.newsHeading).facebook} target="_blank" rel="noopener noreferrer" aria-label="facebook"><i className="fa fa-facebook" aria-hidden="true"></i></Link>
                          &nbsp;&nbsp;  &nbsp;&nbsp;
                          <Link style={{ fontSize: 22, color: "grey" }} to={generateShareLinks(newsDetails.mainTazaKhabars.mainKhabarId, newsDetails.mainTazaKhabars.newsHeading).whatsapp} target="_blank" rel="noopener noreferrer" aria-label="whatsapp"><i className="fa fa-whatsapp" aria-hidden="true"></i></Link>
                          &nbsp;&nbsp;  &nbsp;&nbsp;
                          <Link style={{ fontSize: 22, color: "grey" }} to={generateShareLinks(newsDetails.mainTazaKhabars.mainKhabarId, newsDetails.mainTazaKhabars.newsHeading).twitter} target="_blank" rel="noopener noreferrer" aria-label="twitter"><i className="fa fa-twitter" aria-hidden="true"></i></Link>
                          &nbsp;&nbsp;  &nbsp;&nbsp;
                          <Link style={{ fontSize: 22, color: "grey" }} to={generateShareLinks(newsDetails.mainTazaKhabars.mainKhabarId, newsDetails.mainTazaKhabars.newsHeading).instagram} target="_blank" rel="noopener noreferrer" aria-label="instagram"><i className="fa fa-instagram" aria-hidden="true"></i></Link>
                        </div>
                      </div>
                    </header>
                    <div className="ak-article-inner">

                      <div className="ak-article-inner">
                        <div className="ak-post-featured">
                          <div className="ak-featured-cover">
                            <Link to="wp-content/uploads/sites/26/2022/10/post-18.jpg" aria-label={newsDetails.mainTazaKhabars &&
                              stripHtmlTags(
                                newsDetails.mainTazaKhabars.newsHeading,
                                60
                              )}>
                              <div
                                className="ak-featured-thumb lazy-thumb size-auto"
                                style={{ paddingBottom: "50%" }}
                              >
                                <img
                                  width={750}
                                  height={375}
                                  src={
                                    image_resize +
                                    "?url=" +
                                    BASE_URL +
                                    newsDetails.mainTazaKhabars.newsImage +
                                    "&w=770&h=385&outtype=webp"
                                  }
                                  className="attachment-newsy_750x375 size-newsy_750x375 wp-post-image lazyautosizes lazyloaded"
                                  alt=""
                                  decoding="async"
                                  fetchpriority="high"
                                  data-src={
                                    image_resize +
                                    "?url=" +
                                    BASE_URL +
                                    newsDetails.mainTazaKhabars.newsImage +
                                    "&w=770&h=385&outtype=webp"
                                  }
                                  data-sizes="auto"
                                  data-srcset={
                                    image_resize +
                                    "?url=" +
                                    BASE_URL +
                                    newsDetails.mainTazaKhabars.newsImage +
                                    "&w=770&h=385&outtype=webp"
                                  }
                                  data-expand={700}
                                  sizes="750px"
                                  srcSet={
                                    image_resize +
                                    "?url=" +
                                    BASE_URL +
                                    newsDetails.mainTazaKhabars.newsImage +
                                    "&w=770&h=385&outtype=webp"
                                  }
                                />
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="ak-post-content">
                          <p
                            className="has-drop-cap"
                            id="has-drop-cap"
                            dangerouslySetInnerHTML={renderHtmlContent(newsDetails.mainTazaKhabars.newsDetails)}
                          />
                        </div>
                        <ButtonTags />
                      </div>
                    </div>
                  </div>
                  <div
                    className="grid-item ak_column_1 col-md-3 sidebar-column sidebar-column-primary-right sticky-sidebar"
                    style={{
                      position: "relative",
                      overflow: "visible",
                      boxSizing: "border-box",
                      minHeight: 1
                    }}
                  >
                    <div
                      className="sticky"
                      style={{
                        paddingTop: 0,
                        paddingBottom: 1,
                        position: "sticky",
                        transform: "none",
                        top: 0,
                        left: "914.5px"
                      }}
                    >
                      <div className="sidebar post-sidebar">
                        <MadhyapradeshDetails2 />

                        <div className='mt-2'>
                          <MadhyapradeshDetails3 />
                        </div>


                      </div>
                      <div
                        className="resize-sensor"
                        style={{
                          position: "absolute",
                          inset: 0,
                          overflow: "hidden",
                          zIndex: -1,
                          visibility: "hidden"
                        }}
                      >
                        <div
                          className="resize-sensor-expand"
                          style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            overflow: "hidden",
                            zIndex: -1,
                            visibility: "hidden"
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              left: 0,
                              top: 0,
                              transition: "all 0s ease 0s",
                              width: 410,
                              height: 1260
                            }}
                          />
                        </div>
                        <div
                          className="resize-sensor-shrink"
                          style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            overflow: "hidden",
                            zIndex: -1,
                            visibility: "hidden"
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              left: 0,
                              top: 0,
                              transition: "0s",
                              width: "200%",
                              height: "200%"
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

      </div>


    </div>
  )
}

export default MadhyapradeshDetailsD
